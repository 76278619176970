import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";
import Typography from "@mui/material/Typography";
import {
  Box,
  ThemeProvider,
  IconButton,
  Chip,
  Menu,
  styled,
  Button,
  Grid,
  Modal,
  Switch,
} from "@mui/material";
import launchIcon from "../../assets/images/launch-icon.svg";
import {
  QuickSearchToolbar,
  pageTheme,
  StyledDataGrid,
  CustomLoadingOverlay,
  ActionButton,
  SecondaryButton,
} from "../../ayudas/Themes";
import {
  Delete,
  EmailRounded,
  MoreVert,
  PlayCircleOutlineRounded,
  StopCircleRounded,
  Upcoming,
} from "@mui/icons-material";
import ModalAdvanceInfo from "./AdvanceInfo/AdvanceInfo";
import ModalCreate from "./ModalCreate/ModalCreate";
import { deleteIOC } from "../../Services/IOCExecution";
import { ConvertDateUtc } from "../../ayudas/GlobalData";
import { GridOverlay } from "@mui/x-data-grid-pro";
import GeneralModalDelete from "../../ayudas/CommonComponents/GeneralDeleteModal";
import axios from "axios";
import { env2 } from "../../ayudas/Instance";
import { endPoint } from "../../Services/EndPoint";
import AxiosFetch from "../../Services/FetchServices";
import { rolValidation } from "../../ayudas/ScriptsFunctions";
import TablePaginationActions from "../../ayudas/TablePaginations";
import { PrimaryButton } from "../Packages/PackageTheme";
import { enqueueSnackbar } from "notistack";

const ButtonLauncher = styled(Button)({
  padding: "10px",
  color: "#fff",
  backgroundColor: "#b69141",
});

export default function Email(props) {
  const { Reload, ReloadClientIOC } = props;
  const [ReloadPage, setReloadPage] = useState(Reload);
  const [rows, setRows] = useState([]);
  const [totalRows, settotalRows] = useState([]);
  const rowsPerPage = 10;
  //const [ExportData, setExportData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortModel, setSortModel] = useState([
    { field: "updatedAt", sort: "desc" },
  ]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [IDexecute, setIDexecute] = useState("");
  const [signalReload, setsignalReload] = useState(0);
  const [loading, setLoading] = useState(false);

  const [ActiveCampaign, setActiveCampaign] = useState(false);
  const [SelectRow, setSelectRow] = useState("");

  const cookies = useMemo(() => new Cookies(), []); // Wrap in useMemo with an empty dependency array

  useEffect(() => {
    cookies.set("CurrentPageDataPagination", "360", {
      path: "/",
      SameSite: "none",
      secure: true,
    });
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const url = env2.api + endPoint.allIOC;
    async function GetData() {
      setLoading(true);
      const data = await AxiosFetch("GET", url, source);
      if (data) {
        setLoading(false);
      }
      const response = data?.msg ? data.msg : null;
      if (response?.result && response.query) {
        let array = [];
        response.result?.forEach((element, index) => {
          array.push({
            id: element._id,
            name: element.name,
            tasks: element.tasks,
            client: element.lastName,
            createdBy: element?.createdBy ? element.createdBy.userName : "N/A",
            updatedAt: ConvertDateUtc(element.updatedAt),
            status:
              index === 0
                ? 2
                : Math.round(Math.random() * (3 - 2) + 2) % 2 === 0
                ? 1
                : 3,
            distribution: Math.round(Math.random() * (3 - 1) + 1),
            tagStatus:
              element.isTest === "tested" ? "Controlled" : "Uncontrolled",
          });
        });
        setRows(array);
        settotalRows(array);
      } else {
        setRows([]);
        settotalRows([]);
      }
    }
    GetData();
    if (Reload !== 0) setReloadPage(Reload);
    return () => {
      source.cancel();
    };
  }, [cookies, Reload, ReloadPage, ReloadClientIOC, signalReload]);

  const handleOpen = (id) => {
    setIDexecute(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenDelete = (id) => {
    setIDexecute(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const columns = [
    {
      field: "status",
      headerName: "Type of Event",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,

      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Status</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{ width: "120px" }}
          variant="outlined"
          label={
            params.value === 1
              ? "Created"
              : params.value === 2
              ? "Initiated"
              : "Concluded"
          }
          color={
            params.value === 1
              ? "info"
              : params.value === 2
              ? "success"
              : "error"
          }
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "updatedAt",
      headerName: "Last modification",
      flex: 0.5,
    },
    {
      field: "tagStatus",
      headerName: "Type of Event",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,

      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Type of Event</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{ width: "120px" }}
          variant="outlined"
          label={params.value}
          color={params.value === "Controlled" ? "info" : "warning"}
        />
      ),
    },
    {
      field: "distribution",
      headerName: "Distribution",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,

      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Distibution</Box>
      ),
      renderCell: (params) => (
        <Chip
          style={{ width: "120px" }}
          variant="outlined"
          label={
            params.value === 1
              ? "Email"
              : params.value === 2
              ? "Url Link"
              : "QR Code"
          }
          color={
            params.value === 1
              ? "success"
              : params.value === 2
              ? "info"
              : "secondary"
          }
        />
      ),
    },
    {
      field: "Campaing",
      headerName: "Status Campaign",
      type: "singleSelect",
      flex: 0.5,
      minWidth: 150,

      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>
          Status of Campaign
        </Box>
      ),
      renderCell: (params) => (
        <Switch
          disabled={params.row.status === 3}
          checked={params.row.status === 2}
          color="primary"
        />
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 0.5,
      minWidth: 150,

      sortable: false,
      filterable: false,
      align: "center",
      renderHeader: () => (
        <Box style={{ textAlign: "center", width: "100%" }}>Actions</Box>
      ),
      renderCell: (params) => (
        <>
          <ModalAdvanceInfo ExecutionInfo={params.row} />
          {/* <Tooltip title="Delete"> */}
          <IconButton
            disabled={rolValidation()}
            className="iconButtonSize"
            onClick={handleOpenDelete.bind(this, params.row.id)}
          >
            <Delete />
          </IconButton>
          {/* </Tooltip> */}
        </>
      ),
    },
  ];

  const DelIOC = async () => {
    const response = await deleteIOC(IDexecute);
    if (response) {
      setOpenDelete(false);
      setsignalReload(signalReload + 1);
    }
  };

  const ExecuteIOC = () => {
    let Request = {
      IOC: IDexecute,
    };
    props.Execute(Request);
    handleClose();
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileId = "page-menu-mobile";
  const renderMobileMenu = (
    <Box>
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <ModalCreate
          ResetInsider={(e) => props.ResetInsider(e)}
          ReloadPage={ReloadClientIOC}
          Reload={() => {
            setsignalReload(signalReload + 1);
          }}
        />
      </Menu>
    </Box>
  );

  return (
    <Box style={{ width: "100%" }}>
      <Modal open={open} onClose={handleClose}>
        <Box className="contenido" style={{ maxWidth: "500px" }}>
          <Box className="header">
            <Typography className="headerTitle" style={{ textAlign: "center" }}>
              Launch
            </Typography>
            <Typography
              className="comn-label-dash"
              style={{ textAlign: "center" }}
            >
              Do you want to launch this Email Validation?
            </Typography>
          </Box>

          <Grid container>
            <Grid item md={12}>
              <Box style={{ textAlign: "center" }}>
                <ActionButton onClick={ExecuteIOC.bind()}>Launch</ActionButton>
                <SecondaryButton onClick={handleClose.bind()}>
                  Cancel
                </SecondaryButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <GeneralModalDelete
        ExternalOpen={openDelete}
        HeaderText=""
        BodyText="Do you want to Delete this Attack Campaign?"
        CloseAction={handleCloseDelete}
        PrimaryAction={DelIOC}
        CancelAction={handleCloseDelete}
      />
      <ThemeProvider theme={pageTheme}>
        <Typography variant="pageTitle">
          <EmailRounded
            className="rt-icon-header"
            style={{ color: "#c59d48" }}
          />
          Attack Campaigns
          <Box
            sx={{
              float: { xs: "none", md: "right" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <SecondaryButton
              sx={{ float: "right" }}
              startIcon={
                !ActiveCampaign ? (
                  <PlayCircleOutlineRounded />
                ) : (
                  <StopCircleRounded />
                )
              }
              onClick={() => {
                if (SelectRow !== "") {
                  if (!ActiveCampaign) {
                    enqueueSnackbar("Initite campaign", { variant: "success" });
                  } else {
                    enqueueSnackbar("Conclude campaign", {
                      variant: "success",
                    });
                  }
                  setActiveCampaign(!ActiveCampaign);
                } else {
                  enqueueSnackbar("Select one campaign to initite ", {
                    variant: "warning",
                  });
                }
              }}
            >
              {!ActiveCampaign ? "Initiate Campaign" : "Conclude Campaign"}
            </SecondaryButton>
            <ModalCreate
              ResetInsider={(e) => props.ResetInsider(e)}
              ReloadPage={ReloadClientIOC}
              Reload={() => {
                setsignalReload(signalReload + 1);
              }}
            />
          </Box>
          <Box
            sx={{
              float: { xs: "right", md: "none" },
              display: { xs: "inline-block", md: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              disabled={rolValidation()}
            >
              <MoreVert />
            </IconButton>
          </Box>
        </Typography>
        {renderMobileMenu}
        <Box
          style={{
            width: "100%",
            backgroundColor: "#242933",
            border: " 1px solid #090f1a",
            padding: "15px",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",
            borderRadius: "14px",
            marginTop: "24px",
            height: "auto",
          }}
        >
          <div style={{ height: "auto", width: "100%" }}>
            <StyledDataGrid
              slotProps={{
                pagination: {
                  ActionsComponent: TablePaginationActions,
                  showFirstButton: true,
                  showLastButton: true,
                },
              }}
              slots={{
                loadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: () => (
                  <GridOverlay>
                    <div>No Data Results</div>
                  </GridOverlay>
                ),
                noResultsOverlay: () => (
                  <GridOverlay>
                    <div>No Results Filter Data</div>
                  </GridOverlay>
                ),
              }}
              disableColumnSelector
              disableSelectionOnClick
              suppressPaginationPanel={true}
              disableDensitySelector
              disableColumnMenu
              disableRowSelectionOnClick
              rows={rows}
              columns={columns}
              autoHeight={true}
              initialState={{
                pagination: { paginationModel: { pageSize: rowsPerPage } },
              }}
              loading={loading}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              pagination
              pageSizeOptions={[5, 10, 20]}
              density="comfortable"
            />
          </div>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
