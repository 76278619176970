import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Chip,
  Grid2,
  MenuItem,
  Modal,
  Select,
  Step,
  StepLabel,
  Stepper,
  TablePagination,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ActionButton,
  ColorlibConnector,
  ColorlibStepEmail,
  ColorlibStepIOC,
  FormInputTextField,
  pageTheme,
  PrimaryButton,
  SecondaryButton,
  STATUS_TAG,
  StyledDataGrid,
} from "../../../ayudas/Themes";
import DetailmodalAgent from "../../Agents/DetailAgents/DetailAgent";

import Sat from "../../../assets/SAT-01.jpg";
import LinuxIcon from "../../../assets/images/linux-icon.svg";
import MacIcon from "../../../assets/images/apple-logo.svg";
import "./Create.scss";
import TransferListIOC from "./TransferList";
import { addIOCAsync } from "../../../Services/IOCExecution";
import { includes } from "lodash-es";
import { PadNumbers } from "../../../ayudas/GlobalData";
import { GetDate, rolValidation } from "../../../ayudas/ScriptsFunctions";
import axios from "axios";
import { endPoint } from "../../../Services/EndPoint";
import { env2 } from "../../../ayudas/Instance";
import AxiosFetch from "../../../Services/FetchServices";
import { enqueueSnackbar } from "notistack";
import {
  Delete,
  DesktopAccessDisabledRounded,
  DesktopWindows,
  DesktopWindowsRounded,
  EmailRounded,
  LinkRounded,
  QrCode2Outlined,
  Streetview,
} from "@mui/icons-material";
import TablePaginationActions from "../../../ayudas/TablePaginations";

const StepLetters = { color: "#fff" };

export default function ModalCreate(props) {
  const { ReloadPage } = props;
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [TotalListOnline, setTotalListOnline] = useState([]);
  const [ListTransfer, setListTransfer] = useState([]);
  const [left, setLeft] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26,
  ]);
  const [SelectEndopoint, setSelectEndopoint] = useState("");
  const [Name, setName] = useState("");
  const [disabledButton, setdisabledButton] = useState(false);
  const [argComm5, setargComm5] = useState(0);
  const [argComm10, setargComm10] = useState(0);
  const [signalReload, setsignalReload] = useState(0);

  const [control, setControl] = useState(true);
  const [threatCretion, setThreatCretion] = useState(-1);
  const [distribution, setdistribution] = useState(-1);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const url = env2.api + endPoint.onlineClients;
    async function GetData() {
      const response = await AxiosFetch("GET", url, source);
      if (response?.msg && response.msg.query) {
        let array = [];
        response.msg.result.forEach((element) => {
          if (element.fk_agent.platform === "Windows") {
            array.push(element);
          }
        });
        setTotalListOnline(array);
      } else {
        setTotalListOnline([]);
      }
    }
    GetData();
    return () => {
      source.cancel();
    };
  }, [ReloadPage, signalReload]);

  const [tempRows, settempRows] = useState([]);
  const [Count, setCount] = useState(0);
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function GetData() {
      const baseUrl = `${env2.api}${endPoint.artifactsPageAdvanced}`;
      const paginationParams = `10/1`;
      const sortParams = `col=lastModification&order=desc`;

      const url = `${baseUrl}${paginationParams}?${sortParams}`;

      const data = await AxiosFetch("POST", url, source, JSON.stringify({}));

      const response = data?.msg ? data.msg : null;
      if (response?.result) {
        let dataArtifacts = response.result;
        let array = [];
        if (
          dataArtifacts?.artifacts &&
          Array.isArray(dataArtifacts.artifacts)
        ) {
          dataArtifacts.artifacts.forEach((element) => {
            let type =
              element.threatType === "Zero-Day"
                ? "ZeroDay"
                : element.threatType;
            type = element.threatType === "Midd" ? "Mid" : type;
            array.push({
              tagTest: element.tagTest,
              id: element.id,
              name: element.name,
              phaseAttacks: "",
              threatType: type,
              lastModification: GetDate(element.lastModification),
              actions: element,
              urlVirusTotal: element.urlVirusTotal,
              urlMitre: element.urlMitre,
            });
          });
          let NoTotal = dataArtifacts.info.neto;
          setCount(NoTotal);
          settempRows(array);
        } else {
          setCount(0);
          settempRows([]);
        }
      } else {
        setCount(0);
        settempRows([]);
      }
    }
    GetData();

    return () => {};
  }, []);

  const Columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      filterable: false,
      sortable: false,
      minWidth: 40,
      align: "center",
      renderHeader: () => <Box style={{ color: "#0a0818" }}>|</Box>,
      renderCell: (params) => (
        <>
          <Checkbox style={{ padding: 10 }} />
        </>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "threatType",
      headerName: "Challenge Level",
      sortable: false,
      filterable: false,
      flex: 0.5,
    },
    {
      field: "tagTest",
      headerName: "Status",
      sortable: false,
      filterable: false,
      flex: 0.5,
      align: "center",
      renderCell: (params) => (
        <Chip
          size="small"
          variant="outlined"
          label={STATUS_TAG[params.value].label}
          color={STATUS_TAG[params.value].chipColor}
        />
      ),
    },
    {
      field: "lastModification",
      headerName: "Date Uploaded",
      flex: 1,
      filterable: false,
    },
  ];
  const handleClose = () => {
    setActiveStep(0);
    setListTransfer([]);
    setSelectEndopoint("");
    setName("");
    setLeft([
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26,
    ]);
    setargComm5(0);
    setargComm10(0);
    setOpen(false);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleList = (array) => {
    let data = array.length > 0 ? array : [];
    setListTransfer(data);
  };
  const handleSelectAgent = (id) => {
    setSelectEndopoint(id);
  };
  const handleName = (e) => {
    setName(e.target.value);
  };
  const createEXecution = async () => {
    setdisabledButton(true);
    if (TotalListOnline === "Without results" || TotalListOnline.length === 0) {
      setdisabledButton(false);
      enqueueSnackbar("Endpoint selected is offline", { variant: "info" });
    } else {
      let array = [];
      ListTransfer.forEach((num) => {
        let argument = [];
        if (num === 5) {
          argument.push(argComm5.toString());
        }
        if (num === 10) {
          argument.push(argComm10.toString());
        }
        array.push({ task: num.toString(), arguments: argument });
      });
      let IOCdata = {
        name: Name,
        tasks: array,
        client: SelectEndopoint,
      };
      const response = await addIOCAsync(IOCdata);
      if (response === true) {
        props.Reload();
        setdisabledButton(false);
        handleClose();
      } else {
        setdisabledButton(false);
      }
    }
  };
  const handleLeftDataTranfers = (array) => {
    setLeft(array);
  };
  const IOCStepper = (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector />}
    >
      <Step key={0}>
        <StepLabel StepIconComponent={ColorlibStepEmail}>
          <Typography style={StepLetters}>Payload Select</Typography>
        </StepLabel>
      </Step>
      <Step key={1}>
        <StepLabel StepIconComponent={ColorlibStepEmail}>
          <Typography style={StepLetters}>Threat Creation</Typography>
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel StepIconComponent={ColorlibStepEmail}>
          <Typography style={StepLetters}>Distribution Selection</Typography>
        </StepLabel>
      </Step>
      <Step key={3}>
        <StepLabel StepIconComponent={ColorlibStepEmail}>
          <Typography style={StepLetters}>Details Campaign</Typography>
        </StepLabel>
      </Step>
      <Step key={4}>
        <StepLabel StepIconComponent={ColorlibStepEmail}>
          <Typography style={StepLetters}>Save and Deploy</Typography>
        </StepLabel>
      </Step>
    </Stepper>
  );

  const IOCStepStart = (
    <Box className="ContentStepOne">
      <Box display={"inline-flex"}>
        <Card
          sx={{
            padding: "15px",
            margin: "15px 15px",
            cursor: "pointer",
            maxWidth: 345,
            minHeight: 350,
            boxShadow: control ? "-2px 4px 4px #c59d48" : "none",
            backgroundColor: control ? "#c59d48aa" : "transparent",
            ".MuiCardContent-root": {
              paddingTop: "0px !important",
            },
          }}
          onClick={() => {
            setControl(true);
          }}
        >
          <Box className="ContainerIconExecution">
            <Tooltip title="Controlled">
              <DesktopWindowsRounded className="IconExecution" />
            </Tooltip>
          </Box>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Realistic and Controlled Attack Campaigns
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Artifacts generated either by choosing from the EVE library or
              generated by scripts will be automatically handled by EVE to
              control methods: Report to EVE platform ,Status of execution ,C2
              configured
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            padding: "15px",
            cursor: "pointer",
            margin: "15px 15px",
            maxWidth: 345,
            minHeight: 350,
            boxShadow: !control ? "-2px 4px 4px #c59d48" : "none",

            backgroundColor: !control ? "#c59d48aa" : "transparent",
            ".MuiCardContent-root": {
              paddingTop: "0px !important",
            },
          }}
          onClick={() => {
            setControl(false);
          }}
        >
          <Box className="ContainerIconExecution">
            <Tooltip title="Network">
              <DesktopAccessDisabledRounded className="IconExecution" />
            </Tooltip>
          </Box>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Unattended Attack Campaign
            </Typography>
            <Typography variant="body2" color="text.secondary">
              The unattended campaign will allow clients to use their own
              artifacts and/or scripts with which they wish to test their cyber
              defenses in a realistic way and obviously following the
              cybersecurity conventions they previously define.
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <PrimaryButton onClick={handleNext}>Start</PrimaryButton>
      </Box>
    </Box>
  );
  const styleButton = {
    height: "60px",
    width: "60%",
  };
  const IOCStepCommand = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">Select type of malware</Typography>
      <Grid2 container spacing={0}>
        <Grid2 item size={2}></Grid2>
        <Grid2 item size={4}>
          <Button
            sx={styleButton}
            style={{
              color: "#ffffff",
              fontSize: "20px",
              backgroundColor:
                threatCretion === 1 ? "#c59d48aa" : "transparent",
              boxShadow: threatCretion === 1 ? "-2px 4px 4px #c59d48" : "none",
            }}
            onClick={() => {
              setThreatCretion(1);
            }}
            variant="outlined"
          >
            Threat
          </Button>
        </Grid2>

        <Grid2 item size={4}>
          <Button
            sx={styleButton}
            style={{
              color: "#ffffff",
              fontSize: "20px",
              backgroundColor:
                threatCretion === 2 ? "#c59d48aa" : "transparent",
              boxShadow: threatCretion === 2 ? "-2px 4px 4px #c59d48" : "none",
            }}
            onClick={() => {
              setThreatCretion(2);
            }}
            variant="outlined"
          >
            Custom Threat
          </Button>
        </Grid2>
        <Grid2 item size={2}></Grid2>
        <Grid2 item size={1}></Grid2>
        <Grid2 item size={10} padding={"15px 7px"}>
          <StyledDataGrid
            slotProps={{
              pagination: {
                ActionsComponent: TablePaginationActions,
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            density="compact"
            pagination
            // pageSize={rowsPerPage}
            pageSizeOptions={[5, 10, 20]}
            // rowCount={props.rowCount}
            paginationMode="server"
            // onPageSizeChange={(rowsPerPage) => {setRowsPerPage(rowsPerPage); }}
            // onPageChange={(newPage) => setPage(newPage)}
            sortingMode="server"
            columns={Columns}
            rows={tempRows}
            rowCount={Count}
            loading={false}
            disableColumnMenu={true}
            disableSelectionOnClick
            autoHeight
          />
        </Grid2>
      </Grid2>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={threatCretion === -1}>
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepEndPoints = (
    <Box className="ContentStepOne">
      <Box display={"inline-flex"}>
        <Card
          sx={{
            padding: "15px",
            margin: "15px 15px",
            cursor: "pointer",
            maxWidth: 345,
            minHeight: 100,
            boxShadow: distribution === 1 ? "-2px 4px 4px #c59d48" : "none",
            backgroundColor: distribution === 1 ? "#c59d48aa" : "transparent",
            ".MuiCardContent-root": {
              paddingTop: "0px !important",
            },
          }}
          onClick={() => {
            setdistribution(1);
          }}
        >
          <Box className="ContainerIconExecution">
            <Tooltip title="Controlled">
              <EmailRounded className="IconExecution" />
            </Tooltip>
          </Box>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Email
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            padding: "15px",
            cursor: "pointer",
            margin: "15px 15px",
            maxWidth: 345,
            minHeight: 100,
            boxShadow: distribution === 2 ? "-2px 4px 4px #c59d48" : "none",

            backgroundColor: distribution === 2 ? "#c59d48aa" : "transparent",
            ".MuiCardContent-root": {
              paddingTop: "0px !important",
            },
          }}
          onClick={() => {
            setdistribution(2);
          }}
        >
          <Box className="ContainerIconExecution">
            <Tooltip title="Network">
              <QrCode2Outlined className="IconExecution" />
            </Tooltip>
          </Box>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              QR Code
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            padding: "15px",
            cursor: "pointer",
            margin: "15px 15px",
            maxWidth: 345,
            minHeight: 100,
            boxShadow: distribution === 3 ? "-2px 4px 4px #c59d48" : "none",

            backgroundColor: distribution === 3 ? "#c59d48aa" : "transparent",
            ".MuiCardContent-root": {
              paddingTop: "0px !important",
            },
          }}
          onClick={() => {
            setdistribution(3);
          }}
        >
          <Box className="ContainerIconExecution">
            <Tooltip title="Network">
              <LinkRounded className="IconExecution" />
            </Tooltip>
          </Box>

          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              URL Link
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Grid2 container spacing={0}>
        <Grid2 item size={6}>
          <Select value={"sat"} sx={{ width: "50%" }}>
            <MenuItem value="sat">Sat Template</MenuItem>
          </Select>
        </Grid2>
        <Grid2 item size={6}>
          <Typography>Destination(s) </Typography>
          <TextField />
        </Grid2>
        <Grid2 item size={6}>
          <img src={Sat} alt="" style={{ width: "50%" }} />
        </Grid2>
        <Grid2 item size={6} display={"block"}>
          <Chip
            label="carlos.moreno@rthreat.com"
            variant="outlined"
            icon={<Delete />}
          ></Chip>
          <Chip
            label="carlos.moreno@rthreat.com"
            variant="outlined"
            icon={<Delete />}
          ></Chip>
          <Chip
            label="carlos.moreno@rthreat.com"
            variant="outlined"
            icon={<Delete />}
          ></Chip>
          <Chip
            label="carlos.moreno@rthreat.com"
            variant="outlined"
            icon={<Delete />}
          ></Chip>
          <Chip
            label="carlos.moreno@rthreat.com"
            variant="outlined"
            icon={<Delete />}
          ></Chip>
          <Chip
            label="carlos.moreno@rthreat.com"
            variant="outlined"
            icon={<Delete />}
          ></Chip>
        </Grid2>
      </Grid2>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={distribution === -1}>
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepName = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">Enter Name Campaign</Typography>
      <Box className="IOCNameContent">
        <FormInputTextField
          label="Name Campaign"
          value={Name}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleName}
        />
      </Box>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton onClick={handleNext} disabled={Name === ""}>
          Next
        </PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepLaunch = (
    <Box className="ContentStepOne">
      <Typography variant="subTitleContent">Details Campaign</Typography>
      <Box>
        <Typography>Name : {Name}</Typography>
        <Typography>Malware Type: Threath</Typography>
        <Typography>Threat Name : Perrito.exe</Typography>
        <Typography>Distribution: Email</Typography>
        <Typography>EVE Controlled</Typography>
      </Box>
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <PrimaryButton onClick={handleNext}>Next</PrimaryButton>
      </Box>
    </Box>
  );
  const IOCStepSave = (
    <Box className="ContentStepOne">
      <Box>
        <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
        <SecondaryButton onClick={handleNext}>Save</SecondaryButton>
        <PrimaryButton>Deploy</PrimaryButton>
      </Box>
    </Box>
  );
  const body = (
    <Box className="ModalSettion" >
      <Box className="GeneralContent">
        <>{IOCStepper}</>
      </Box>
      <Box className="GeneralContent" sx={{height:"95%"}}>
        {activeStep === 0 ? <>{IOCStepStart}</> : null}
        {activeStep === 1 ? <>{IOCStepCommand}</> : null}
        {activeStep === 2 ? <>{IOCStepEndPoints}</> : null}
        {activeStep === 3 ? <>{IOCStepName}</> : null}
        {activeStep === 4 ? <>{IOCStepLaunch}</> : null}
        {activeStep === 5 ? <>{IOCStepSave}</> : null}
      </Box>
    </Box>
  );
  return (
    <ThemeProvider theme={pageTheme}>
      <PrimaryButton disabled={rolValidation()} onClick={handleOpen}>
        New Attack Campaign
      </PrimaryButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </ThemeProvider>
  );
}
