import { useState } from "react";
import {
  Box,
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import "../../../../General.scss";
import "../../styles/User.scss";
import { ActionButton, SecondaryButton } from "../../../../ayudas/Themes";
import Profile1 from "../../../../assets/images/select-profile-1.png";
import Profile2 from "../../../../assets/images/select-profile-2.png";
import Profile3 from "../../../../assets/images/select-profile-3.png";
import Profile4 from "../../../../assets/images/select-profile-4.png";
import Profile5 from "../../../../assets/images/select-profile-5.png";
import Profile6 from "../../../../assets/images/select-profile-6.png";
import Profile7 from "../../../../assets/images/select-profile-7.png";
import Profile8 from "../../../../assets/images/select-profile-8.png";
import Profile9 from "../../../../assets/images/select-profile-9.png";
import { useSnackbar } from "notistack";
import { env } from "../../../../env";

export default function Detailmodal(props) {
  const { User, DataUser, expandButton, rol } = props;
  const [open, setOpen] = useState(false);
  const [firstName, setfirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [fk_rol, setRol] = useState(rol);
  const types = ["admin", "user"];
  const [Icon, setIcon] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [activeOTP, setActiveTOTP] = useState(false);
  const handleChangeTOTP = (event) => {
    setActiveTOTP(event.target.checked);
  };

  const handleIcon = (Select) => {
    setIcon(Select);
  };
  const handleOpen = async () => {
    if (User.urlIma) {
      if (typeof User.urlIma === "number") {
        setIcon(User.urlIma - 1);
      } else {
        let splitdata = User.urlIma.split("/");
        let image = splitdata[splitdata.length - 1].split(".");
        let number = parseInt(image[0], 10);
        setIcon(number);
      }
    }
    var urlGetUsers = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/`;
    var requestHeaders = new Headers();
    requestHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    requestHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: requestHeaders,
      redirect: "follow",
    };

    const response = await fetch(urlGetUsers, requestOptions).then((response) =>
      response.json()
    );

    if (response) {
      let user = [];
      for (let index = 0; index < response.length; index++) {
        const userview = response[index];

        if (userview.email === User.email) {
          user = userview;
          break;
        }
      }
      let name = User?.firstName ? User.firstName : User.firtsName;
      setfirstName(name);
      setLastName(User.lastName);
      setEmail(User.email);
      setRol(User.rol);
      setActiveTOTP(user.totp);
      setOpen(true);
    }
  };

  const handleName = (e) => {
    e.preventDefault();
    if (e.target.value !== " ") {
      setfirstName(e.target.value);
    }
  };
  const handleLastName = (e) => {
    e.preventDefault();

    if (e.target.value !== " ") {
      setLastName(e.target.value);
    }
  };

  const handleEmail = (e) => {
    e.preventDefault();

    if (e.target.value !== " ") {
      setEmail(e.target.value);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (User.rol !== fk_rol) {
      var urlGetSessions = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/${User.id}/logout`;
      var requestHeadersSession = new Headers();
      requestHeadersSession.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      requestHeadersSession.append("Content-Type", "application/json");

      var requestOptionsSession = {
        method: "POST",
        headers: requestHeadersSession,
        redirect: "follow",
      };

      await fetch(urlGetSessions, requestOptionsSession).then(
        async (response) => {
          if (response.ok || response.status === 204) {
            enqueueSnackbar("close session", {
              variant: "success",
            });
          }
        }
      );
    }

    if (User.totp && !activeOTP) {
      var urlGetCredential = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/${User.id}/credentials`;
      var requestHeaders = new Headers();
      requestHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      requestHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: requestHeaders,
        redirect: "follow",
      };

      const response = await fetch(urlGetCredential, requestOptions).then(
        (response) => response.json()
      );
      if (response) {
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          if (element.type === "otp") {
            urlGetCredential += "/" + element.id;
            break;
          }
        }

        var requestOptionsDelete = {
          method: "DELETE",
          headers: requestHeaders,
          redirect: "follow",
        };
        await fetch(urlGetCredential, requestOptionsDelete).then(
          async (response) => {
            if (response.ok || response.status === 204) {
              enqueueSnackbar("disable OPT success", {
                variant: "success",
              });
            }
          }
        );
      }
    }

    const body = {
      id: User.id,
      enabled: true,
      email: email,
      firstName: firstName,
      lastName: lastName,
      attributes: {
        rol: fk_rol,
        avatar: Icon,
      },
    };
    if (!User.totp && activeOTP) {
      if (activeOTP) {
        body.requiredActions = ["CONFIGURE_TOTP"];
      }
    }

    var url = `${env.REACT_APP_KEYCLOAK_INSTANCE}/admin/realms/${env.REACT_APP_KEYCLOAK_REALM}/users/${User.id}`;
    var requestHeadersUpdate = new Headers();
    requestHeadersUpdate.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    requestHeadersUpdate.append("Content-Type", "application/json");

    var requestOptionsUpdate = {
      method: "PUT",
      headers: requestHeadersUpdate,
      body: JSON.stringify(body),
      redirect: "follow",
    };
    await fetch(url, requestOptionsUpdate)
      .then((response) => {
        if (response.ok || response.status === 204) {
          props.Reload(body);
          setOpen(false);
          enqueueSnackbar("update data successfully", { variant: "success" });
        } else {
          console.log("error", response.statusText);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const body = (
    <Box className="contenido" sx={{ maxHeight: "90%", height: "auto" , overflow:"auto"}}>
      <Box className="header">
        <Box className="header">
          <Typography className="headerTitle">Edit User</Typography>
        </Box>
        <Grid container>
          <Grid item size={12} className="bdr-grid">
            <Box>
              <Typography className="comn-label-dash">
                Select Profile picture
              </Typography>
              <Box
                style={{
                  padding: "0",
                  flexBasis: "0",
                  flexGrow: "1",
                  textAlign: "center",
                }}
              >
                <img
                  src={Profile1}
                  alt=""
                  onClick={handleIcon.bind(this, 0)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 0 ? " 75px" : "55px",
                    height: Icon === 0 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile2}
                  alt=""
                  onClick={handleIcon.bind(this, 1)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 1 ? " 75px" : "55px",
                    height: Icon === 1 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile3}
                  alt=""
                  onClick={handleIcon.bind(this, 2)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 2 ? " 75px" : "55px",
                    height: Icon === 2 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile4}
                  alt=""
                  onClick={handleIcon.bind(this, 3)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 3 ? " 75px" : "55px",
                    height: Icon === 3 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile5}
                  alt=""
                  onClick={handleIcon.bind(this, 4)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 4 ? " 75px" : "55px",
                    height: Icon === 4 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile6}
                  alt=""
                  onClick={handleIcon.bind(this, 5)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 5 ? " 75px" : "55px",
                    height: Icon === 5 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile7}
                  alt=""
                  onClick={handleIcon.bind(this, 6)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 6 ? " 75px" : "55px",
                    height: Icon === 6 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile8}
                  alt=""
                  onClick={handleIcon.bind(this, 7)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 7 ? " 75px" : "55px",
                    height: Icon === 7 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
                <img
                  src={Profile9}
                  alt=""
                  onClick={handleIcon.bind(this, 8)}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    width: Icon === 8 ? " 75px" : "55px",
                    height: Icon === 8 ? " 75px" : "55px",
                    marginLeft: "15px",
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            size={6}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">First name</Typography>
              <Box>
                <input
                  id="user-name"
                  type="text"
                  className="input-style-users"
                  onChange={handleName}
                  value={firstName}
                ></input>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            size={6}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">Last name</Typography>
              <Box>
                <input
                  type="text"
                  className="input-style-users"
                  id="LName"
                  onChange={handleLastName}
                  value={lastName}
                ></input>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            size={env.REACT_APP_INTERNAL_CRUD_USER === "true" ? 6 : 12}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">Email address</Typography>
              <Box>
                <input
                  type="text"
                  className="input-style-users"
                  placeholder="Email Address"
                  id="Email"
                  onChange={handleEmail}
                  value={email}
                ></input>
              </Box>
            </Box>
          </Grid>
          {env.REACT_APP_INTERNAL_CRUD_USER === "true" && (
            <Grid
              item
              size={6}
              style={{
                paddingRight: "15px",
                paddingLeft: "15px",
                marginBottom: "15px",
              }}
            >
              <Box>
                <Typography className="comn-label-dash">Role</Typography>
                <Box style={{ display: "flex" }}>
                  <FormControl style={{ width: "800px" }}>
                    <InputLabel
                      id="select-label"
                      style={{
                        display: !fk_rol ? "block" : "none",
                        fontSize: "14px",
                      }}
                    ></InputLabel>
                    <select
                      className="input-style-users"
                      labelId="select"
                      id="Rol"
                      style={{
                        textTransform: "capitalize",
                        fontSize: "16px",
                        color: "white",
                        marginTop: "4px",
                      }}
                      value={fk_rol}
                      disabled={DataUser.user.result.rol === "user"}
                      onChange={(e) => {
                        setRol(e.target.value);
                      }}
                    >
                      {types.map((el) => {
                        return (
                          <option
                            key={el}
                            value={el}
                            style={{
                              textTransform: "capitalize",
                              fontSize: "16px",
                              color: "white",
                            }}
                          >
                            {el}
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid
            item
            size={3}
            style={{
              paddingRight: "15px",
              paddingLeft: "15px",
              marginBottom: "15px",
            }}
          >
            <Box>
              <Typography className="comn-label-dash">
                Second Validation
              </Typography>

              <Box
                sx={{
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "7px",
                }}
              >
                <Typography color={!activeOTP ? "white" : "grey"}>
                  No
                </Typography>

                <Switch
                  checked={activeOTP}
                  onChange={handleChangeTOTP}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography color={activeOTP ? "white" : "grey"}>
                  Yes
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item size={12}>
            {/* <ActionButton
              onClick={() => {
                enqueueSnackbar("hola", { variant: "info" });
              }}
            >
              notific
            </ActionButton> */}
            <Box style={{ textAlign: "center" }}>
              <ActionButton onClick={handleSubmit}>Save</ActionButton>
              <SecondaryButton
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </SecondaryButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  return (
    <div style={{ display: "inline-block" }}>
      {expandButton === true ? (
        <>
          <Box style={{ display: "flex", width: "100%" }} onClick={handleOpen}>
            <Edit style={{ margin: "15px 10px 15px 15px", color: "#fafafa" }} />

            <p>Edit Profile</p>
          </Box>
        </>
      ) : (
        <>
          {/* <Tooltip title="Edit"> */}
          <IconButton
            className="iconButtonSize"
            style={{ color: "#66638f", display: "inline-block" }}
            onClick={handleOpen}
          >
            <Edit style={{ color: "#fafafa" }} />
          </IconButton>
          {/* </Tooltip> */}
        </>
      )}

      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
}
